<template>
	<div class="page flex-col">
		<div class="block_1 flex-col" :class="startX<=600?'wid600':''">
			<div class="" v-if="startX<=600">
				<head-top :type="1" :startX="startX"></head-top>
				<el-carousel height="100%" class="image1">
					<el-carousel-item v-for="item in content.banner.picture" :key="item.id" @click.stop="toIndex(item)">
						<!-- <h3 class="small">{{ item }}</h3> -->
						<img v-if="content.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel>
				<!-- <img class="image1"
					src="./assets/img/ps0485egkbex9s4v0wk8zhdmb5o3iz76wjlycc0b296c-10ff-4243-91e7-b8185f1729b0.png"
					alt=""> -->
			</div>
			<div class="section_1 flex-col" v-else>
				<head-top :type="1" :startX="startX"></head-top>
				<el-carousel height="100%" class="section1">
					<el-carousel-item v-for="item in content.banner.picture" :key="item.id" @click.stop="toIndex(item)">
						<!-- <h3 class="small">{{ item }}</h3> -->
						<img v-if="content.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel>

			</div>
			<div class="section_2 flex-col">
				<div class="text-wrapper_1" id="lingyu">
					<span class="text_10">D</span>
					<span class="text_11">evelopment</span>
					<span class="text_12">a</span>
					<span class="text_13">reas</span>
				</div>
				<span class="text_14" id="lingyu1">业务发展领域</span>
				<div class="image-wrapper_2" :style="'grid-template-columns: repeat(' + widNum + ',1fr);'"
					@mouseleave="territory = -1">
					<div class="flex-col align-center image-wrapper_2_item" :style="'width: ' + widNum1 + ';'"
						@mouseenter="imageEnter(index)" v-for="(item,index) in content.areas" :key="index">
						<img class="image3" referrerpolicy="no-referrer" :src="item.icon_hover"
							v-if="territory == index" />
						<img class="image_3" referrerpolicy="no-referrer" :src="item.icon" v-else />
						<span class="text_15">{{item.name}}</span>
					</div>
				</div>
			</div>
			<div class="section_14 flex-col">
				<div class="box_1 flex-col">
					<div class="flex-col" style="width: 92vw; margin: 0 auto; max-width: 1800px">
						<div class="text-wrapper_4" id="chanpin">
							<span class="text_23">p</span>
							<span class="text_24">roduct</span>
							<span class="text_25">&nbsp;c</span>
							<span class="text_26">enter</span>
						</div>
						<span class="text_27" id="chanpin1">产品中心</span>
					</div>
				</div>
				<div class="box21 mainApp16" v-if="startX>600">
					<div class="box_21 flex-row" id="list1">
						<div class="group_10 flex-col" v-for="(item, index) in content.category" :key="index"
							@mouseenter="showIn1 = index" @mousedown="lashen">
							<img :src="item.cover_vertical" alt="" />
							<div class="block_3 flex-col justify-end"
								:style="showIn1 ==index?'background: linear-gradient(0deg, #0390CF -30%, rgba(3,144,207,0) 60%);':''">
								<span class="text_100">{{ item.name }}</span>
								<!-- <span class="text_101">{{ item.text2 }}</span> -->
								<img v-if="showIn1 ==index" class="label_3" referrerpolicy="no-referrer"
									src="./assets/img/psknshn2g5axjjcey7iq1imbdtw7qzpwc8b2f4eb4b-fce9-42f3-a766-b5f076e53204.png" />
							</div>
						</div>
					</div>
				</div>
				<div class="box21 mainApp24" v-else>
					<div class="box_21 flex-row " id="list2">
						<div class="group_10 flex-col" @mouseenter="showIn1 = index" @mousedown="lashen"
							v-for="(item, index) in content.category" :key="index">
							<img :src="item.cover_vertical" alt="" />
							<div class="block_3 flex-col justify-end"
								:style="showIn1 ==index?'background: linear-gradient(0deg, #0390CF -30%, rgba(3,144,207,0) 60%);':''">
								<span class="text_100">{{ item.name }}</span>
								<!-- <span class="text_101">{{ item.text2 }}</span> -->
								<img v-if="showIn1 ==index" class="label_3" referrerpolicy="no-referrer"
									src="./assets/img/psknshn2g5axjjcey7iq1imbdtw7qzpwc8b2f4eb4b-fce9-42f3-a766-b5f076e53204.png" />
							</div>
						</div>
					</div>
				</div>

				<div class="box_22 mainApp16 align-center flex-row justify-between">
					<img class="image_23" referrerpolicy="no-referrer"
						:src="content.category[showIn1].cover_horizontal" />
					<div class="group_11 flex-col justify-center">
						<span class="text_108">TOP{{content.category[showIn1].top}}</span>
						<img class="image_24" referrerpolicy="no-referrer"
							src="./assets/img/psfzg1f2idn98ffigjx5a619vbpq6p6ta0nf8fbc391-3b7d-4e3e-b0c3-0b3497989622.png" />
						<div class="text-group_7 flex-col justify-between">
							<span class="text_109">{{content.category[showIn1].name}}</span>
							<!-- <span class="text_110">HEAVY&nbsp;INDUSTRYEQUIPMENT</span> -->
						</div>
						<div class="text-wrapper_21 flex-align-justify" @click="toCenter">
							<span class="text_111">更多产品</span>
						</div>
					</div>
				</div>
				<div class="box_23 flex-row justify-between">
					<div class="group_12 flex-col justify-between" @click="showCar(index)"
						v-for="(item,index) in content.category[showIn1].product" :key="index">
						<img class="image_25" referrerpolicy="no-referrer" :src="item.cover" />
						<div class="group_13 flex-row justify-between">
							<span class="text_112">{{item.name}}</span>
							<img class="label_4" referrerpolicy="no-referrer"
								src="./assets/img/ps402yani0v513709azrfdkyhrhs9lpy0ou35cff730-4d18-44ea-ac13-79a3b8032b3e.png" />
						</div>
					</div>
				</div>
			</div>
			<div class="section_3 mainApp16">
				<div class=" flex-col" id="about" style="z-index: 9;position: absolute;top: 0px;transition:all 1s;">
					<div class="text-wrapper_5">
						<span class="text_28">A</span>
						<span class="text_29">bout</span>
						<span class="text_30">U</span>
						<span class="text_31">s</span>
					</div>
					<span class="text_32">公司简介</span>
					<span class="text_33">{{content.about.title}}</span>
					<div class="section_4 flex-col"></div>
					<span class="paragraph_1">
						{{content.about.content}}
					</span>
					<div class="text-wrapper_6 flex-align-justify" @click="toAbout">
						<span class="text_34">查看全部</span>
					</div>
				</div>
				<img class="section3Img"
					src="./assets/img/ps5jxqxzrhvfv3ev18g1sr73b9s4thr0te1ce511a2-4672-490c-a137-2f0502c4942d.png"
					alt="">
			</div>
			<div class="section_5 flex-col">
				<div class="box_2 flex-col">
					<div class="section_6 flex-row">
						<div class="text-wrapper_7" id="down">
							<span class="text_35">S</span>
							<span class="text_36">ubsidiaries</span>
						</div>
					</div>
					<div class="text-wrapper_8 flex-row">
						<span class="text_37" id="down1">下属公司</span>
					</div>
					<div class="section_7 mainApp16 flex-row justify-between">
						<div class="box_3 flex-col flex-align-justify" v-for="(item,index) in content.company"
							:key="index" @mouseenter="showImg1=index" @mouseleave="showImg1=-1">
							<div class="flex-col flex-align-justify">
								<img class="image_11" referrerpolicy="no-referrer" :src="item.logo" />
								<span class="text_38">{{item.name}}</span>
							</div>
							<!--  -->
							<div class="box_24" v-if="showImg1==index">
								<div class="text-wrapper_18 flex-row">
									<span class="text_79">{{item.name}}</span>
								</div>
								<div class="box_25 flex-row">
									<div class="image-text_11 flex-row justify-between">
										<img class="label_1" referrerpolicy="no-referrer" src="./assets/img/tel.png" />
										<span class="text-group_6">{{item.tel_name}}：</span>
									</div>
									<span class="text_80" v-for="(item1,index1) in item.tel"
										:key="index1">{{item1}}</span>
								</div>
								<div class="box_25 flex-row">
									<div class="image-text_11 flex-row justify-between">
										<img class="label_1" referrerpolicy="no-referrer" src="./assets/img/fax.png" />
										<span class="text-group_6">{{item.fax_name}}：</span>
									</div>
									<span class="text_80" v-for="(item1,index1) in item.fax"
										:key="index1">{{item1}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-wrapper_9" id="news">
					<span class="text_44">N</span>
					<span class="text_45">ews</span>
				</div>
				<span class="text_46" id="news1">新闻动态</span>
				<!-- <div class="image-wrapper_4 flex-row">
					<img class="image_17" referrerpolicy="no-referrer" :src="item.lanhuimage0"
						v-for="(item, index) in loopData0" :key="index" />
				</div> -->
				<div class="text-wrapper_10 mainApp16 flex-row justify-between" v-if="startX>600">
					<div class=" flex-col text-wrapper_10_item" v-for="(item,index) in content.news" :key="index">
						<!--  -->
						<div class="image_17" @mouseenter="showImg = index" @mouseleave="showImg = -1">
							<img class="image17" referrerpolicy="no-referrer" :src="item.cover"
								:style="showImg == index&&startX>600?'transform: scale(1.2);':''" />
						</div>

						<!-- <img class="image17" referrerpolicy="no-referrer" :src="item.cover" /> -->
						<span class="text_47">{{item.name}}</span>
						<div class="image_18"></div>
						<div class=" flex-row align-center text50" v-for="(item1,index1) in item.news_list" @click="toDetail(item1.news_id)"
							:key="index1">
							<div class="flex-row align-center">
								<div class="box_7 flex-col"></div>
								<span class="text_50">{{item1.title}}</span>
							</div>
							<span class="text_51">{{item1.create_time}}</span>
						</div>
						<div class="image-text_1 flex-row justify-between align-center"
							@click="toNews(item.category_id)">
							<span class="text-group_1">查看更多</span>
							<img class="thumbnail_2" referrerpolicy="no-referrer"
								src="./assets/img/psm9vi9jao36ndxu7yk9p0g14juf5gg7clbe7b256e-94d8-442b-bca7-ca129cfefe94.png" />
						</div>
					</div>
				</div>
				<div class="text-wrapper_10 mainApp16 flex-row justify-between" id="newsList" v-else>
					<div class=" flex-col text-wrapper_10_item" v-for="(item,index) in content.news" :key="index"
						@mouseenter.stop="showImg = index" @mousedown.stop="lashen1">
						<!--  -->
						<div class="image_17">
							<img class="image17" referrerpolicy="no-referrer" :src="item.cover" />
						</div>
						<!-- <img class="image17" referrerpolicy="no-referrer" :src="item.cover" /> -->
						<span class="text_47">{{item.name}}</span>
						<div class="image_18"></div>
						<div class=" flex-row align-center text50" v-for="(item1,index1) in item.news_list" @click="toDetail(item1.news_id)"
							:key="index1">
							<div class="flex-row align-center">
								<div class="box_7 flex-col"></div>
								<span class="text_50">{{item1.title}}</span>
							</div>
							<span class="text_51">{{item1.create_time}}</span>
						</div>
						<div class="image-text_1 flex-row justify-between align-center"
							@click="toNews(item.category_id)">
							<span class="text-group_1">查看更多</span>
							<img class="thumbnail_2" referrerpolicy="no-referrer"
								src="./assets/img/psm9vi9jao36ndxu7yk9p0g14juf5gg7clbe7b256e-94d8-442b-bca7-ca129cfefe94.png" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
		<el-dialog class="" width="50%" :visible.sync="dialogVisible" @close="dialogVisible=false">
			<el-carousel trigger="click" ref="carousel" :autoplay="false" class="dialog" :initial-index="showNum">
				<el-carousel-item v-for="(item,index) in content.category[showIn1].product" :key="index" class="flex-align-justify flex-col">
					<img :src="item.cover" class="car_img" alt="">
					<div class="text1">{{ item.name }}</div>
					<div class="text2" v-if="item.time">{{ item.time }}</div>
				</el-carousel-item>
			</el-carousel>
			<div></div>
		</el-dialog>
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue";
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		index
	} from "../../util/api";
	export default {
		data() {
			return {
				// loopData0: [{
				// 		lanhuimage0: "https://lanhu.oss-cn-beijing.aliyuncs.com/psur3vw4pb41ebjjn15t8wzfldksr8d1eq93a53a582-b6e0-4b23-ab39-0a2f4c0b50e5",
				// 	},
				// 	{
				// 		lanhuimage0: "https://lanhu.oss-cn-beijing.aliyuncs.com/psefbaf64vx1po3f9tybtejmwp5lt5z8n3b9c7381-406a-4263-b5ea-7510bdbd8883",
				// 	},
				// 	{
				// 		lanhuimage0: "https://lanhu.oss-cn-beijing.aliyuncs.com/pslpxpt0rcpzi3inmx2fnjj5m6jagnrextbc0429252-e8f2-4595-95a0-ad234ee5c23b",
				// 	},
				// ],
				constants: {},
				widNum: 4,
				widNum1: "420px",
				startX: 0,
				startY: 0,
				left: 0,
				left1: 0,
				showIn1: 0,
				dialogVisible: false,
				list: [{
					image: 'dabi.png',
					name: "大臂",
					time: "发布于：2015-7-10 15:19:08 "
				}, {
					image: 'xiaobi.png',
					name: "小臂",
					time: "发布于：2015-7-10 18:19:08 "
				}, {
					image: 'chandou.png',
					name: "铲斗",
					time: "发布于：2015-8-10 18:19:08 "
				}],
				showNum: 1,
				content: {
					category: []
				},
				territory: -1, //业务发展领域鼠标悬浮位置
				showImg: '',
				showImg1: -1,
				isShow: false,
				// currNavbar: ''
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1,
		},
		created() {
			this.getDate()
		},
		mounted() {
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
			window.addEventListener("scroll", this.scrollHandle, true);
			// console.log(this.startX);
		},
		deforeDestroy() {
			window.removeEventListener('scroll', this.scrollHandle, true);
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					if (this.startX < 600) {
						this.widNum = 4;
						this.widNum1 = "30rem";
					} else if (this.startX < 1500) {
						this.widNum = 3;
						this.widNum1 = "400px";
					} else if (this.startX > 1500) {
						this.widNum = 4;
						this.widNum1 = "400px";
					} else if (this.startX < 2200) {
						this.widNum1 = "400px";
					} else {
						this.widNum1 = "500px";
					}
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		methods: {
			getDate() {
				index({
					lang: this.lang
				}).then((res) => {
					// console.log(res);
					this.content = res.data
					// this.title = res.data.header_nav;
				});
			},
			showCar(i) {
				this.showNum = i
				this.dialogVisible = true
				this.$refs.carousel.setActiveItem(this.showNum);
			},
			lashen1(e) {
				console.log("点击", e);
				let _this = this;
				const tarDom = document.getElementById("newsList");
				let flag; // 鼠标按下
				let downX; // 鼠标点击的x下标
				let scrollLeft; // 当前元素滚动条的偏移量
				tarDom.addEventListener("mousedown", function(event) {
					flag = true;
					downX = event.clientX; // 获取到点击的x下标
					scrollLeft = tarDom.scrollLeft; // 获取当前元素滚动条的偏移量
				});
				tarDom.addEventListener("mousemove", function(event) {
					if (flag) {
						// 判断是否是鼠标按下滚动元素区域
						let moveX = event.clientX; // 获取移动的x轴
						let scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
						tarDom.scrollLeft = scrollLeft - scrollX; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
					}
				});
				// 鼠标抬起停止拖动
				tarDom.addEventListener("mouseup", function() {
					flag = false;
				});
				// 鼠标离开元素停止拖动
				tarDom.addEventListener("mouseleave", function(event) {
					flag = false;
				});

			},
			lashen(e) {
				console.log("点击", e);
				let _this = this;
				if (this.startX > 600) {
					const tarDom = document.getElementById("list1");
					let flag; // 鼠标按下
					let downX; // 鼠标点击的x下标
					let scrollLeft; // 当前元素滚动条的偏移量
					tarDom.addEventListener("mousedown", function(event) {
						flag = true;
						downX = event.clientX; // 获取到点击的x下标
						scrollLeft = tarDom.scrollLeft; // 获取当前元素滚动条的偏移量
					});
					tarDom.addEventListener("mousemove", function(event) {
						if (flag) {
							// 判断是否是鼠标按下滚动元素区域
							let moveX = event.clientX; // 获取移动的x轴
							let scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
							tarDom.scrollLeft = scrollLeft - scrollX; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
						}
					});
					// 鼠标抬起停止拖动
					tarDom.addEventListener("mouseup", function() {
						flag = false;
					});
					// 鼠标离开元素停止拖动
					tarDom.addEventListener("mouseleave", function(event) {
						flag = false;
					});
				} else {
					const tarDom = document.getElementById("list2");
					let flag; // 鼠标按下
					let downX; // 鼠标点击的x下标
					let scrollLeft; // 当前元素滚动条的偏移量
					tarDom.addEventListener("mousedown", function(event) {
						flag = true;
						downX = event.clientX; // 获取到点击的x下标
						scrollLeft = tarDom.scrollLeft; // 获取当前元素滚动条的偏移量
					});
					tarDom.addEventListener("mousemove", function(event) {
						if (flag) {
							// 判断是否是鼠标按下滚动元素区域
							let moveX = event.clientX; // 获取移动的x轴
							let scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
							tarDom.scrollLeft = scrollLeft - scrollX; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
						}
					});
					// 鼠标抬起停止拖动
					tarDom.addEventListener("mouseup", function() {
						flag = false;
					});
					// 鼠标离开元素停止拖动
					tarDom.addEventListener("mouseleave", function(event) {
						flag = false;
					});
				}
			},
			imageEnter(index) {
				setTimeout(() => {
					this.territory = index
				}, 1000)
			},
			toIndex(v) {
				if (v.target_rule == 1) {
					this.$router.push("/")
				} else if (v.target_rule == 2) {
					this.$router.push("/aboutUs");
				} else if (v.target_rule == 3) {
					let id = v.param
					this.$router.push("/news/" + id)
				} else if (v.target_rule == 4) {
					let news_id = v.param
					this.$router.push("/newDetail/" + news_id)
				} else if (v.target_rule == 5) {
					let id = v.param
					console.log(id);
					this.$router.push("/product/" + id)
				} else if (v.target_rule == 6) {
					this.$router.push("/companies")
				} else if (v.target_rule == 7) {
					this.$router.push("/Investor")
				} else if (v.target_rule == 8) {
					this.$router.push("/invite")
				} else if (v.target_rule == 9) {
					this.$router.push("/contact")
				}
			},
			toDetail(news_id) {
				this.$router.push("/newDetail/" + news_id);
			},
			toNews(id){
				this.$router.push("/news/"+id);
			},
			toCenter() {
				this.$router.push("/product/1");
			},
			toAbout(){
				this.$router.push("/aboutUs");
			},
			scrollHandle() {
				if (this.startX > 600) {
					let lingyu = this.isInViewPortOfOne(document.getElementById('lingyu'))
					if (lingyu) {
						document.getElementById('lingyu').style.fontSize = '72px'
						document.getElementById('lingyu').style.opacity = '1'
						document.getElementById('lingyu1').style.fontSize = '40px'
						document.getElementById('lingyu1').style.opacity = '1'
					} else {
						document.getElementById('lingyu').style.fontSize = '2px'
						document.getElementById('lingyu').style.opacity = '0'
						document.getElementById('lingyu1').style.fontSize = '2px'
						document.getElementById('lingyu1').style.opacity = '0'
					}
					let chanpin = this.isInViewPortOfOne(document.getElementById('chanpin'))
					if (chanpin) {
						document.getElementById('chanpin').style.fontSize = '72px'
						document.getElementById('chanpin').style.opacity = '1'
						document.getElementById('chanpin1').style.fontSize = '40px'
						document.getElementById('chanpin1').style.opacity = '1'
					} else {
						document.getElementById('chanpin').style.fontSize = '2px'
						document.getElementById('chanpin').style.opacity = '0'
						document.getElementById('chanpin1').style.fontSize = '2px'
						document.getElementById('chanpin1').style.opacity = '0'
					}
					let about = this.isInViewPortOfOne(document.getElementById('about'))
					if (about) {
						document.getElementById('about').style.top = '0px'
						document.getElementById('about').style.opacity = '1'
					} else {
						document.getElementById('about').style.top = '50px'
						document.getElementById('about').style.opacity = '0'
					}
					let down = this.isInViewPortOfOne(document.getElementById('down'))
					if (down) {
						document.getElementById('down').style.fontSize = '72px'
						document.getElementById('down').style.opacity = '1'
						document.getElementById('down1').style.fontSize = '40px'
						document.getElementById('down1').style.opacity = '1'
					} else {
						document.getElementById('down').style.fontSize = '2px'
						document.getElementById('down').style.opacity = '0'
						document.getElementById('down1').style.fontSize = '2px'
						document.getElementById('down1').style.opacity = '0'
					}
					let news = this.isInViewPortOfOne(document.getElementById('news'))
					if (news) {
						document.getElementById('news').style.fontSize = '72px'
						document.getElementById('news').style.opacity = '1'
						document.getElementById('news1').style.fontSize = '40px'
						document.getElementById('news1').style.opacity = '1'
					} else {
						document.getElementById('news').style.fontSize = '2px'
						document.getElementById('news').style.opacity = '0'
						document.getElementById('news1').style.fontSize = '2px'
						document.getElementById('news1').style.opacity = '0'
					}
				}

			},
			isInViewPortOfOne(el) {
				// viewPortHeight 兼容所有浏览器写法
				const offset = el.getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					return true
				} else {
					return false
					// console.log('移出可视区域');
				}
			},
		}
	}
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped lang="scss">
	.wid600 {
		width: 100%;

		.section_2 {
			.text-wrapper_1 {
				margin-top: 40px;
				line-height: 21px;

				>span {
					line-height: 21px;
					font-size: 26px;
				}
			}

			.text_14 {
				margin-top: 12px;
				font-size: 15px;
				line-height: 20px;
			}

			.image-wrapper_2 {
				margin-top: 30px;

				.image-wrapper_2_item {
					margin-bottom: 26px;
					cursor: pointer;

					.image_3 {
						width: 55px;
						height: 55px;
					}

					.image3 {
						width: 55px;
						height: 55px;
					}

					.text_15 {
						font-size: 12px;
					}
				}
			}
		}

		.section_14 {
			.box_1 {
				.text-wrapper_4 {
					margin-top: 45px;
					line-height: 26px;
					text-transform: uppercase;

					>span {
						font-size: 26px;
						line-height: 26px;
					}

				}

				.text_27 {
					font-size: 15px;
					margin-top: 13px;
					margin-bottom: 108px;
					line-height: 20px;
				}
			}

			.box21 {
				height: 261px;
				margin-top: -79px;
			}

			.box_21 {
				width: 100%;
				height: 261px;
				// margin: 0 auto;


				.group_10 {
					width: 142px;
					height: 249px;
					position: initial;
					margin-right: 15px;

					&:nth-last-child(1) {
						margin-right: 0px;
						// margin-left: -130px;
					}

					.block_3 {
						padding-left: 10px;
						padding-bottom: 10px;
						width: 142px;
						height: 249px;

						.text_100 {
							font-size: 9px;
							line-height: 9px;
						}

						.text_101 {
							font-size: 9px;
							line-height: 9px;
							margin-top: 6px;
							margin-bottom: 9.5px;
							width: 92%;
							overflow: hidden;
						}

						.label_3 {
							width: 13.5px;
							height: 8px;
							margin-top: 2px;
						}
					}
				}
			}

			.box_22 {
				width: 100%;
				margin-top: 21px;
				flex-direction: column;

				.image_23 {
					width: 116rem;
					height: 62rem;
					// width: 345px;
					// height: 190px;
					margin: 0 auto;

				}

				.group_11 {
					// width: 550rem;
					// height: 60rem;
					margin: 0 auto;
					margin-top: 16px;
					width: 97%;

					.text_108 {
						font-size: 26px;
					}

					.image_24 {
						width: 3px;
						height: 16px;
						margin: 5px 0 0 8px;
					}

					.text-group_7 {
						margin-top: 24px;

						.text_109 {
							font-size: 13px;
						}

						.text_110 {
							margin-top: 7.5px;
							font-size: 9px;
						}
					}

					.text-wrapper_21 {
						width: 73px;
						height: 22px;
						background: #0390CF;
						margin-top: 19px;

						.text_111 {
							font-size: 7px;
						}
					}
				}
			}

			.box_23 {
				width: 100%;
				margin-top: 21px;
				justify-content: flex-start;
				overflow-x: auto;
				
				&::-webkit-scrollbar {
					display: none;
				}

				.group_12 {
					// width: 170px;
					// height: 150px;
					width: 56rem;
					height: 50rem;
					margin-right: 3rem;

					.image_25 {
						// width: 170px;
						// height: 124px;
						width: 100%;
						height: 41rem;
					}

					.group_13 {
						width: 100%;
						// height: 10rem;
						margin: 0;
						// margin-top: 6rem;
						padding-left: 6px;

						>span {
							font-size: 9px;
							line-height: 9px;
						}

						.label_4 {
							width: 15px;
							height: 15px;
						}
					}
				}
			}
		}

		.section_3 {
			width: 100%;
			margin-top: 180px;
			min-width: 200px;
			min-height: 200px;

			#about {
				position: relative !important;
				top: 0px !important;
				opacity: 1 !important;
			}

			.section3Img {
				width: 100vw;
				height: 55vw;
				position: relative;
				margin-top: -30vw;
			}

			.text-wrapper_5 {
				margin: -160px 0 0 20px;
				text-transform: uppercase;

				>span {
					font-size: 26px;
				}
			}

			.text_32 {
				margin: 12px 0 0 20px;
				font-size: 15px;
			}

			.text_33 {
				margin: 24px 0 0 20px;
				font-size: 14px;
			}

			.section_4 {
				width: 17.5px;
				height: 2px;
				margin: 12px 0 0 20px;
			}

			.paragraph_1 {
				width: 90%;
				margin: 21px 0 0 20px;
				font-size: 11px;
			}

			.text-wrapper_6 {
				width: 73px;
				height: 22px;
				margin: 33px 0 0px 20px;

				.text_34 {
					font-size: 7.5px;
				}
			}
		}

		.section_5 {
			align-items: flex-start;

			.box_2 {
				.section_6 {
					text-transform: uppercase;
					margin-top: 40px;

					.text-wrapper_7 {
						font-size: 26px !important;
						opacity: 1 !important;
					}
				}

				.text-wrapper_8 {
					margin-top: 12px;

					.text_37 {
						font-size: 15px !important;
						opacity: 1 !important;
					}
				}

				.section_7 {
					width: 100%;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					// margin-top: 60px;
					// margin-bottom: 40px;
					margin-top: 20rem;
					margin-bottom: 13rem;
					min-width: 200px;

					.box_3 {
						width: 57rem;
						height: 30rem;
						// width: 185px;
						// height: 95px;
						margin: 0 auto;
						margin-bottom: 15px;

						.image_11 {
							width: 51px;
							height: 15px;
							margin: 0 auto;
						}

						.text_38 {
							margin: 0 auto;
							margin-top: 12px;
							font-size: 3rem;
							width: 90%;
							white-space: pre-line;
						}
					}
				}
			}

			.text-wrapper_9 {
				text-transform: uppercase;
				margin-top: 34px;
				margin-left: 15px;
				font-size: 0;
				opacity: 1 !important;

				>span {
					font-size: 24px;
					line-height: 24px;
				}
			}

			.text_46 {
				width: auto;
				margin-top: 15px;
				margin-left: 15px;
				font-size: 14px !important;
				line-height: 14px;
				opacity: 1 !important;
			}

			.text-wrapper_10 {
				padding: 0 15px;
				margin-bottom: 24px;
				justify-content: flex-start;
				overflow-x: auto;

				&::-webkit-scrollbar {
					display: none;
				}

				.text-wrapper_10_item {
					width: 300px;

					.image_17 {
						width: 270px;
						height: 144px;
					}

					.text_47 {
						font-size: 12px;
						margin-top: 20px;
						margin-bottom: 9px;
					}

					.image_18 {
						width: 270px;
					}

					.text50 {
						margin-top: 15px;
						

						.box_7 {
							width: 3.5px;
							height: 3.5px;
						}

						.text_50 {
							font-size: 3rem;
							font-family: Noto Sans S Chinese;
						}

						.text_51 {
							font-size: 3rem;
							margin-left: 21px;
						}
					}

					.image-text_1 {
						justify-content: flex-start;

						>span {
							font-size: 9px;
						}

						.thumbnail_2 {
							width: 7px;
							height: 4.5px;
						}
					}
				}


			}
		}
	}
</style>